/*-----------------------------------------------------------------------------------

    Template Name:Chatloop APP
    Template URI: themes.pixelstrap.com/chatloop
    Description: This is App Landing Page
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------

01.General CSS
02.Pre-loader & tap on top css
03.Home page css
04.about section css
05.feature section css
06.testimonial section css
07.screenshots section css
08.work section css
09.Team section css
10.price section css
11.Blog section css
12.Download section css
13.Contact section css
14.footer section css
15.Blog pages css
16.inner Page css
17 Review page css
18.Faq Page css
19.Download page css
20.coming soon pages css
21.authentication pages css
22.Landing page css(is's only for theme showcase you can remove it :))
23.responsive css
 */

// @import 'loaders.css/src/animations/ball-scale.scss';

/*=====================
    01.General CSS start
==========================*/
html {
  min-height: 100%;
  height: 100%;
  // height: 100vh;
}

body {
  font-family: $font_0, $font_1;
  background-color: $white;
  position: relative;
  font-size: 14px;
  overflow-x: hidden;
  height: inherit;
  //  background: $bg_gradiant;
  //  background-color: $secondary_color;
}

#root {
  height: 100%;
}

h1 {
  line-height: 1.4em;
  font-size: 42px;
  color: hsla(0, 0%, 100%, 0.85);
}

h2 {
  line-height: 28px;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: $primary_color;
}

h3 {
  line-height: 28px;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  color: #586082;
  letter-spacing: 1px;
}

h4 {
  line-height: 28px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 0;
  color: $white;
  letter-spacing: 1px;
  margin-top: 0px;
}

h5 {
  line-height: 28px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 0;
  letter-spacing: 0.1em;
  color: $white;
}

h6 {
  line-height: 28px;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 25px;
  color: $theme-gray;
  margin-bottom: 25px;
}

p {
  line-height: 23px;
  font-size: 16px;
  color: $theme-gray;
  letter-spacing: 0.05rem;
}

a {
  &:hover {
    color: $secondary_color;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.theme-bg {
  background: $bg_gradiant;
}

.owl-theme {
  &.owl-carousel {
    .owl-nav {
      button {

        &.owl-next,
        &.owl-prev {
          background-color: $white !important;
          padding: 5px 15px !important;
          font-size: 18px;
          color: $primary_color;
        }
      }
    }
  }

  .owl-dots {
    height: 17px;

    .owl-dot {
      span {
        background-color: $white;
        border: 1px solid $primary_color;
      }

      &.active,
      &:hover {
        span {
          background: $bg_gradiant;
          border: 1px solid $white;
        }
      }
    }
  }

  .owl-dots {
    .owl-dot {
      span {
        margin-bottom: 0;
      }
    }
  }

  &.owl-nav {
    .owl-dots {
      .owl-dot {
        span {
          margin-bottom: 0;
        }
      }
    }

    [class*="owl-"]:hover {
      background: transparent;
    }
  }
}

.text-black {
  color: $black !important;
}

button:focus {
  outline: none;
}

.f-bold {
  font-weight: 700;
}

.f-color {
  color: $theme-gray;
}

.ml-12 {
  margin-left: 12px;
}

.section-title {
  margin-bottom: 50px;
}

.theme-text {
  color: $secondary_color !important;
}

.margin-50 {
  margin-top: 50px;
}

.btn-theme {
  background-color: $primary_color;
  background: $bg_gradiant;
  color: $white !important;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 30px;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  border: 0;
  letter-spacing: 1px;

  &:hover {
    background: $primary_color;
    background-color: $primary_color;
  }
}

.btn-theme-primary {
  background-color: $secondary_color;
  color: $white !important;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 50px;
  padding: 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease-in;
  border: 1px solid $secondary_color;

  &:hover {
    background-color: transparent;
    color: $secondary_color !important;
    border: 1px solid $secondary_color;
    transition: all 0.3s ease-in-out;
  }
}

.d-flex-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.display-flex {
  display: flex;
  align-items: center;
}

#instantclick-bar {
  background: $secondary_color;
}

section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}

.margin-top-100 {
  margin-top: 110px;
}

/*=====================
    01.General CSS end
==========================*/
/*=====================
    02.Pre Loader start & tap on top
==========================*/
.tap-top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 150px;
  right: 30px;
  z-index: 99;
  color: $white;
  text-align: center;
  background: $bg_gradiant;
  border-radius: 100%;
  font-size: 22px;
  border: 1px solid $white;
  cursor: pointer;
  display: none;

  i {
    vertical-align: middle;
  }
}

.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: $white;
  display: block;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1031;
  top: 0;
}

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -48%); // top: calc(50% - 20px);
  // left: calc(50% - 20px);
}

@keyframes loader {
  0% {
    left: -100px;
  }

  100% {
    left: 110%;
  }
}

#box {
  width: 50px;
  height: 50px;
  background: $bg_gradiant;
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}

@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

#shadow {
  width: 50px;
  height: 5px;
  background: $black;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}

@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 70px;
  height: 70px;
  margin: 6px;
  border: 8px solid #ff9d00;
  border-radius: 70%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff9d00 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*=====================
    02.Pre Loader start & tap on Ends
==========================*/

/*=====================
    03.Home page css start
==========================*/
.theme-nav {
  padding-top: 0px;
  padding-bottom: 0px;
  transition: all 0.3s ease;

  .navbar {
    background-color: transparent;
  }

  .nav-link {
    display: block;
    padding: 25px 1rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
  }

  .nav-item {
    font-size: 14px;
    padding: 20px 5px;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
  }

  .dropdown-menu {
    .nav-item {
      font-size: 13px;
      padding: 0;
    }

    .nav-link {
      color: $primary_color !important;
    }
  }

  &.darkHeader {
    padding-top: 0;
    padding-bottom: 0;

    .nav-item {
      padding: 0px 5px;
      transition: all 0.3s ease;
    }

    .dropdown-menu .nav-item {
      padding: 0;

      .nav-link {
        &.active {
          color: #18e7d3;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .navbar-nav .active>.nav-link {
    color: $secondary_color;
  }

  .navbar-toggler-icon i {
    color: $white;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.navbar-toggler {
  padding-right: 0;

  .navbar-toggler-icon {
    width: auto;
  }
}

.navbar-nav {
  .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }

  >li.dropdown {
    position: relative;
  }

  a {
    &.nav-link {
      &.active {
        color: $white;
      }
    }
  }
}

.btn-menu {
  background-color: $secondary_color;
  color: $white !important;
  border-radius: 50px;
  padding: 10px 54px;
  width: 150px;
  text-align: center;
}

.darkHeader {
  background: $bg_gradiant;
  box-shadow: 1px 1px 35px 0 $color_mine_shaft_40_approx;
  transition: all 0.3s ease;
}

.slide-bg {
  background: $bg_gradiant;
  height: 100vh;
  padding-bottom: 0;
  padding-top: 80px;

  .container {
    height: 100%;

    .row {
      height: 100%;

      .home-right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
}

.slide-text {
  margin-top: -20px;

  h4 {
    font-size: 18px;
    color: #cfcaff;
    font-weight: 400;
  }
}

.slid-btn {
  margin-top: 70px;

  a:first-child {
    margin-right: 15px;
  }
}

.main-title {
  color: $white;
  font-weight: 600;
}

.sub-title {
  font-size: 20px;
  margin-bottom: 0;
}

.mobile-slid {
  text-align: center;
}

.profile-msg {
  position: absolute;
  top: 41%;
  left: -25px;

  img {
    animation: movebounce 2.1s linear infinite;
  }
}

@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(8px);
  }

  100% {
    transform: translateX(0px);
  }
}

.awesome {
  position: absolute;
  top: 56%;
  right: 25px;

  img {
    animation: moveleftbounce 2.1s linear infinite;
  }
}

.profile-1 {
  position: absolute;
  bottom: 7%;
  right: -30px;

  &:before {
    animation: 2.5s anim-effct-slide 0s linear infinite;
    box-shadow: 0 0 0 80px $color_gray_nurse_approx;
    position: absolute;
    content: "";
    width: 110px;
    height: 110px;
    top: 26%;
    left: 26%;
    border-radius: 50%;
    z-index: 0;
  }
}

.emoji {
  position: absolute;
  left: 10%;
  bottom: 15%;

  img {
    animation: moveleftbounce 2.1s linear infinite;
  }
}

.profile-2 {
  position: absolute;
  left: 0;
  top: 5%;

  &:before {
    animation: 2.5s anim-effct-slide1 0s linear infinite;
    box-shadow: 0 0 0 80px rgba(232, 232, 232, 0.1);
    position: absolute;
    content: "";
    width: 110px;
    height: 110px;
    top: 26%;
    left: 26%;
    border-radius: 50%;
    z-index: 0;
  }
}

@-webkit-keyframes anim-effct-slide {
  0% {
    box-shadow: 0 0 0 8px $color_bon_jour_approx;
    opacity: 1;
  }

  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }

  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

@keyframes anim-effct-testi {
  0% {
    box-shadow: 0 0 0 8px $color_bon_jour_approx;
    opacity: 1;
  }

  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }

  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

@-webkit-keyframes anim-effct-testi-back {
  0% {
    box-shadow: 0 0 0 8px $color_cararra_11_approx;
    opacity: 1;
  }

  50% {
    box-shadow: 0 0 0 15px $color_cararra_11_approx;
    opacity: 1;
  }

  100% {
    box-shadow: 0 0 0 25px $color_cararra_11_approx;
    opacity: 1;
  }
}

@keyframes anim-effct-testi-back {
  0% {
    box-shadow: 0 0 0 8px $color_cararra_11_approx;
    opacity: 1;
  }

  50% {
    box-shadow: 0 0 0 15px $color_cararra_11_approx;
    opacity: 1;
  }

  100% {
    box-shadow: 0 0 0 25px $color_cararra_11_approx;
    opacity: 1;
  }
}

@-webkit-keyframes anim-effct-slide1 {
  0% {
    box-shadow: 0 0 0 8px rgba(232, 232, 232, 0.1);
    opacity: 1;
  }

  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }

  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}

/*=====================
    03.Home page css ends
==========================*/

/*=====================
    14.footer section start
==========================*/
.social-bg {
  background-color: $primary_color;
  padding: 15px 0;
}

.social-footer {
  margin-top: 30px;

  ul li {
    display: inline-flex;
    height: 35px;
    width: 35px;
    background: $primary_color;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    transform: scale(1);
    transition: all 0.3s ease;

    a {
      color: $white !important;
    }

    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease;
    }
  }
}

.copy-right-bg {
  padding: 25px;
}

.copyright {
  margin: 20px 0 0px;
  font-size: 14px;
  color: #c6c6c6;
  font-family: $font_2;
  line-height: 6px;
  letter-spacing: 0px;
  width: 100%;
  text-align: center;
}

.copyrightNew {
  margin: 0px 0 0px;
  font-size: 12px;
  color: #505780;
  font-family: $font_2;
  line-height: 6px;
  letter-spacing: 0px;
  width: 100%;
  text-align: center;
}

@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }

  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}

@-webkit-keyframes ripple1 {
  0% {
    -ms-transform: scale(5.5);
    /* IE 9 */
    -webkit-transform: scale(5.5);
    /* Safari */
    transform: scale(5.5);
    opacity: 0.3;
  }

  100% {
    -ms-transform: scale(8.5);
    /* IE 9 */
    -webkit-transform: scale(8.5);
    /* Safari */
    transform: scale(8.5);
    opacity: 0;
  }
}

@keyframes ripple2 {
  0% {
    -ms-transform: scale(3.5);
    /* IE 9 */
    -webkit-transform: scale(3.5);
    /* Safari */
    transform: scale(3.5);
  }

  100% {
    -ms-transform: scale(5.5);
    /* IE 9 */
    -webkit-transform: scale(5.5);
    /* Safari */
    transform: scale(5.5);
  }
}

@-webkit-keyframes ripple2 {
  0% {
    -ms-transform: scale(3.5);
    /* IE 9 */
    -webkit-transform: scale(3.5);
    /* Safari */
    transform: scale(3.5);
  }

  100% {
    -ms-transform: scale(5.5);
    /* IE 9 */
    -webkit-transform: scale(5.5);
    /* Safari */
    transform: scale(5.5);
  }
}

@keyframes ripple3 {
  0% {
    -ms-transform: scale(1.5);
    /* IE 9 */
    -webkit-transform: scale(1.5);
    /* Safari */
    transform: scale(1.5);
  }

  100% {
    -ms-transform: scale(3.5);
    /* IE 9 */
    -webkit-transform: scale(3.5);
    /* Safari */
    transform: scale(3.5);
  }
}

@-webkit-keyframes ripple3 {
  0% {
    -ms-transform: scale(1.5);
    /* IE 9 */
    -webkit-transform: scale(1.5);
    /* Safari */
    transform: scale(1.5);
  }

  100% {
    -ms-transform: scale(3.5);
    /* IE 9 */
    -webkit-transform: scale(3.5);
    /* Safari */
    transform: scale(3.5);
  }
}

/*=====================
    14.footer section ends
==========================*/

/*=====================
    15.Blog pages css start
==========================*/
.blog-list,
.blog-page {
  .news-slid {
    .blog-hover {
      .blog-details-list {
        li {
          border-color: $theme-gray;

          a {
            color: $theme-gray;
          }
        }
      }

      position: relative;
      transform: translateY(0px);
      padding: 0;

      h4 {
        color: $primary_color;
        margin-top: 5px;
        margin-bottom: 10px;
      }

      ul {
        margin-bottom: 10px;
      }
    }
  }

  .btn-theme {
    padding: 5px 14px;
    font-size: 13px;
  }

  margin-bottom: -40px;

  >div+div+div .blog-item {
    margin-top: 50px;
  }
}

.blog-page {
  margin-bottom: 0;

  .blog-details {
    &.news-slid {
      margin-bottom: 0;
    }
  }

  .animation-circle {
    i {
      background: #eee;
      z-index: -1;
    }
  }

  .news-slid {
    .news-box {
      &:before {
        display: none;
      }
    }
  }
}

.breadcrumb-bg {
  padding: 50px 0;
  margin: 0 auto;
  background-color: #f8f8fa;

  h2 {
    margin-bottom: 0;
    font-size: 28px;
    display: flex;
    align-items: center;
    height: 100%;
    text-transform: uppercase;
  }
}

.theme-breadcrumb {
  .breadcrumb-item {
    line-height: 1;

    &+.breadcrumb-item {
      &::before {
        color: $theme-gray;
      }
    }
  }

  float: right;
  padding: 15px 0;

  li {
    display: inline-block;
    color: $primary_color;
    text-decoration: none;

    &.active {
      a {
        color: $theme-gray;
      }
    }

    a {
      color: $primary_color;
      text-decoration: none;
    }
  }
}

.blog {
  width: 100vw;
  height: 100vh;
  margin-top: 0;
  align-items: center;
}

.inner-page {
  padding-top: 110px;

  footer {
    background-color: #f8f8fa;
  }

  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .navbar {
    background: $bg_gradiant;
  }

  .media h5 {
    color: $theme-gray;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: -5px !important;
  }
}

.blog-pagination {
  margin-top: 80px;
}

.blog-box {
  img {
    opacity: 1;
    transition: 0.3s ease-in-out;
    overflow: hidden;
  }
}

.blog-pagin {
  margin: 0;

  a.page-link {
    color: $primary_color;
    font-size: 16px;
    border: 1px solid $color_gallery_approx;
    transition: all 0.3s ease;
    border-radius: 5px;
    margin: 0 5px;
    padding: 0.5rem 0.9rem;

    &:hover {
      text-decoration: none;
      background-color: $bg_gradiant;
      border-color: $secondary_color;
      color: $white !important;
      transition: all 0.3s ease;
    }
  }
}

.sidebar-space {
  margin-bottom: 50px;
}

.blog-title {
  text-transform: uppercase;
  font-size: 18px;
  color: $theme-gray;
  letter-spacing: 1px;
  margin-top: 0;
}

.blog-divider {
  height: 1px;
  background-color: $color_gallery_approx;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.blog-description {
  margin: 20px auto;
}

.blog-cat-detail {
  ul {
    padding: 0;
    margin: 0;

    li {
      a {
        color: $theme-gray;
        text-transform: capitalize;
        font-size: 15px;
        text-decoration: none;
        transition: 0.3s ease;
        font-weight: 500;
        letter-spacing: 0.5px;

        &:hover {
          color: $primary_color;
          transition: 0.3s ease;
        }
      }

      i {
        margin-right: 15px;
      }
    }
  }

  .marg-15 {
    margin: 15px auto;
  }
}

.reply-comment {
  margin: 30px auto;

  img {
    border-radius: 5px;
  }

  >div+div {
    margin-top: 20px;
  }

  p {
    margin-bottom: 0;
  }
}

.leave-coment {
  margin-top: 30px;

  h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: $primary_color;
  }
}

.theme-form {
  padding-left: 50px;
  padding-right: 50px;

  .form-group {
    margin-bottom: 20px;
  }

  .btn-custom {
    padding: 10px 40px;
  }

  .form-button {
    text-align: center;
  }

  .form-control {
    border-radius: 5px;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid $color_gallery_approx;
    font-size: 12px;
    text-align: center;
  }

  input::-webkit-input-placeholder {
    color: $color_silver_chalice_approx !important;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.1em;
  }
}

.recent-blog .media {
  margin-bottom: 15px;
  align-items: center;

  img {
    height: 60px;
    border-radius: 5px;
  }

  h5 {
    line-height: 24px;
    font-size: 14px;
    font-weight: 600;
    color: $theme-gray;
    text-transform: capitalize;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50%;
  }

  p {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $theme-gray;
  }
}

.repay-coment>div+div {
  margin-top: 20px;
}

/*=====================
    15.Blog pages css ends
==========================*/

/*========================
 21.authentication pages css start
==========================*/
.auth-form {
  .social-btns {
    button {
      margin: 0 5px;
      border: none;
    }

    .fb,
    .ggl {
      &:hover {
        color: $white !important;
      }
    }

    .fb {
      background: #3b5998;
    }

    .ggl {
      background: #d85040;
    }
  }

  .or-saparator {
    position: relative;

    span {
      background-color: $white;
      padding: 0 10px;
      z-index: 2;
      position: relative;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      margin-top: 1px;
      width: 100%;
      content: "";
      background: rgba(40, 56, 76, 0.1);
      z-index: 1;
    }
  }

  .forgot-pass {
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    color: $primary_color;
    position: absolute;
    border-left: 1px solid #d8d8d8;
    top: 6px;
    right: 5px;
  }

  .form-group {
    position: relative;
    margin-bottom: 20px;

    &:hover {
      label {
        opacity: 1;
        top: -9px;
      }
    }

    label {
      padding: 0 7px;
      background: $secondary_color;
      color: #fff;
      border-radius: 3px;
      opacity: 0;
      z-index: 1;
      position: absolute;
      top: 9px;
      left: 15px;
      transition: all 0.3s ease 0s;
    }

    i {
      line-height: 50px;
      color: $theme-gray;
      text-align: center;
      position: absolute;
      font-size: 16px;
      top: -2px;
      left: 20px;
      z-index: 1;
    }
  }

  .form-control {
    height: 45px;
    padding: 10px 52px;
    position: relative;
    transition: all 0.3s ease 0s;

    &:focus {
      border-color: $secondary_color;
      box-shadow: none;
    }
  }

  textarea {
    height: 100px !important;
  }
}

.auth-page {
  min-height: 100vh;
  display: flex;
  align-items: center;

  .animation-circle {
    i {
      top: 0;
      background: #eee;
    }
  }

  .animation-circle-inverse {
    i {
      bottom: 0;
    }
  }

  .profile-1 {
    bottom: 0;
  }
}

.auth-card {
  width: 550px;
  margin: 0 auto;
  position: relative;
  padding: 40px;
  background-color: $white;
  box-shadow: 0 0 35px 2px rgba(0, 0, 0, 0.05);

  p {
    margin-bottom: 0;
  }
}

.form-control {
  &.is-valid {
    border-color: $color_alto_approx;
  }

  &.is-invalid {
    border-color: $color_alto_approx;
  }
}

.was-validated {
  .custom-select {
    &:valid {
      border-color: $color_alto_approx;
    }

    &:invalid {
      border-color: $color_alto_approx;
    }
  }

  .form-control {
    &:valid {
      border-color: $color_alto_approx;
    }

    &:invalid {
      border-color: $color_alto_approx;
    }
  }
}

.submit {
  cursor: pointer;
  border-radius: 5px;
  color: $white !important;
  background: $bg_gradiant;
  padding: 5px 20px;
  box-shadow: 0 0 20px 1px $black_4;
  transition: 0.5s all;

  &:hover {
    cursor: pointer;
    background-color: $primary_color;
  }
}

.thanks-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $color_zircon_approx;
  text-align: center;
}

.error-main {
  text-align: center;

  h4 {
    color: $theme-gray;
    font-weight: 400;
    margin: 20px 0;
  }

  .error-font {
    font-size: 300px;
    line-height: 1;
    font-weight: 900;
    color: $primary_color;
    letter-spacing: -25px;

    span {
      color: $theme-gray;
    }
  }
}

.main {
  background-color: $white;
  padding: 30px;
  text-align: center;
  padding-bottom: 0;

  .custom-select {
    &.is-valid {
      border-color: $color_alto_approx;
    }

    &.is-invalid {
      border-color: $color_alto_approx;
    }
  }
}

/*========================
 21.authentication pages css ends
==========================*/

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}

.outerDiv {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .mainHome {
    max-width: 100%;
    // min-height: 45%;
    // background: $bg_gradiant;
    flex: 1;
    //align-items: center;
    display: flex;
    flex-direction: column;
    //  align-items: center;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.brandNameText {
  font-family: $font_2;
  font-size: 30px;
  color: #27272e;
  text-transform: uppercase;
  justify-content: center;
  margin-bottom: 25px;
}

.brandNameTextNew {
  font-family: $font_2;
  font-size: 30px;
  color: #27272e;
  text-transform: uppercase;
  justify-content: center;
  padding-left: 30px;
}

.socialMediaButtons {
  display: flex;
  align-items: center;
  justify-content: center;

  // margin: auto;
  div {
    // align-self: center;
    align-self: flex-start;
    flex-grow: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px 10px;
  }
}

.brandImageRow {
  width: 150px;
  // height: 108px;
  color: rgba(0, 0, 0, 0.2);
  display: flex;
  margin-bottom: 34px;
}

.brandImage {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.innerBlock1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 25px;
}

.innerBlock1New {
  display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  padding-bottom: 10px;
  padding-top: 80px;
  padding-left: 50px;
  padding-right: 50px;
}

.headerSection {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 20px;
}

.shopNowContainer {
  width: 400px;
  margin: auto;
  height: 30px;
  background: $white_01;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  z-index: 2;

  // position: relative;
  h4 {
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0px;
    color: $gray;
    font-family: $font_2;
    text-align: center;
    margin: 0px;
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px !important;
    // letter-spacing: 0px;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, 0%);
    // width: fit-content;
  }

  .productName {
    font-size: 18px;
    margin-top: 0px !important;
    line-height: 29px;
  }
}

.productsListContainer {
  // background: $white_01;
  // background-color: $white_01;

  // height: 45%;

  // border-top-left-radius: 35px;
  // border-top-right-radius: 35px;
  margin: 0px;
  max-width: 100%;
  display: flex;
  flex: 0.5;
  margin-top: -2px;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.bottomPadding {
  padding-bottom: 100px;
}

.bottomPadding-2 {
  padding-bottom: 100px;
}

.bottomPadding-3 {
  padding-bottom: 300px;
}

.bottomPadding-456 {
  padding-bottom: 380px;
}

.padding-default {
  padding-bottom: 100px;
}

.productsListRow {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  // padding-top: 50px;
  justify-content: center;
  // max-width: 60%;
}

.ctaHeading {
  // position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.productDetailContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.75px;

  // flex-direction: column;
  // margin-left: 5px;
  // margin-right: 5px;

  // margin-bottom: 8px;
  .imageDiv {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dadada;
    // border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // max-height: 100%;
      // width: 142px;
      // height: 133px;
      // object-fit: contain;
      // border-radius: 20px;
    }
  }

  .wp-1 {
    height: 500px;
    width: 500px;
  }

  .wp-2 {
    height: 500px;
    width: 500px;
  }

  .wp-3 {
    height: 400px;
    width: 400px;
  }

  .wp-4 {
    height: 300px;
    width: 300px;
  }

  .wp-5 {
    height: 200px;
    width: 200px;
  }

  .wp-all {
    height: 250px;
    width: 250px;
  }

  h4 {
    color: $gray;
    font-size: 18px;
    font-family: $font_3;
    margin-top: 8px;
    margin-bottom: 0px;
    line-height: 22px;
    letter-spacing: 0px;
    max-width: 100px;
    text-align: center;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: X;
    max-height: 44px;
  }

  h3 {
    display: flex;
    color: $color_orange;
    font-size: 21px;
    font-family: $font_2;
    margin: 0px;
    padding: 0px;
    line-height: 21px;
    letter-spacing: 0px;
    margin-bottom: 8px;

    span {
      font-family: $font_1;
      font-size: 14px;
      letter-spacing: 0px;
    }
  }
}

#copyrightOptimizeApp {
  color: $primary_color;
  font-size: 12px;
  margin-top: 0px;
  letter-spacing: 0px;
  font-family: "Poppins-bold";
  // padding-bottom: 15px;
  padding-left: 5px;
  text-decoration: underline;
}

#copyrightOptimizeApp:hover {
  color: $primary_color;
}

#privacyPolicy {
  color: #c6c6c6;
  font-size: 12px;
  margin-top: 0px;
  letter-spacing: 0px;
  font-family: $font_1;
  padding-bottom: 25px;
  text-align: center;
  line-height: 15px;
}

#privacyPolicyNew {
  color: #505780;
  font-size: 12px;
  margin-top: 10px;
  letter-spacing: 0px;
  font-family: $font_1;
  text-align: center;
  // line-height: 15px;
  text-decoration-line: underline;
}

#privacyPolicy:hover {
  color: $primary_color;
}

.cpoy-right-bg {
  // bottom: 0;
  // right: 0;
  // left: 0;
  background: $white_01;
  // position: absolute;
}

.footerDiv {
  background: $white_01;
  max-width: 100%;

  // position: relative;
  div {
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.mobileFooter {
  display: none;
}

.footerDivNew {
  position: fixed;
  bottom: 0;
  max-width: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 50px 0px 50px;
  padding: 10px 50px 10px 50px;
  flex-direction: column;
  background: #f4f4f4;

  div {
    //align-items: center;
    //justify-content: center;
    //display: flex;
  }
}

.socialMediaButtonsMobile {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.footerUpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // margin-bottom: 10px;
}

.productImageRow {
  width: 475px;
  height: 475px;
  border-radius: 10px;
  justify-content: center;
}

.brandImageRowProduct {
  width: 100px;
  color: rgba(0, 0, 0, 0.2);
  display: flex;
  /* margin-bottom: 34px; */
  align-items: center;
  justify-content: center;
}

.prodHeader {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.brandNameTextNewProd {
  font-family: Poppins-bold;
  font-size: 22px;
  color: #27272e;
  text-transform: uppercase;
  justify-content: center;
  padding-left: 20px
}

.shopNowContainerProd {
  border-radius: 6px;
  width: 400px;
  margin: auto;
  padding: 16px 0px 16px 0px;
  background: #16192C;
  z-index: 2;
  text-align: center;
}



.productDetailImage {
  // width: 100%;
  // height: 100%;
  // max-width: 100%;
  max-height: 100%;
  // border-radius: 10px;
}

.innerBlockProduct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding-bottom:  25px;
  // padding-top:  25px;
}

.outerDivProductDetail {
  overflow: scroll;
  min-height: 100%;
  // height: 100vh;
  //display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mainHome {
    max-width: 100%;
    padding: 0px;
    // min-height: 45%;
    // background: $bg_gradiant;
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.topNavBar {
  background-color: rgb(248, 248, 252);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px 10px 16px;
  width: 100%;
  position: fixed;
  top: 0;
}

.topNavBarRTL {
  flex-direction: row-reverse !important;
}

.headLogo {
  width: 50px;
  height: 30px;
  object-fit: contain;
}

.languageButton {
  color: #505780;
  font-size: 12px;
  font-family: $font_0;
  margin-bottom: 0px;
  margin-left: auto;
}

.languageButtonRTL {
  margin-right: auto;
  margin-left: 0px;
}

.productAmount {
  color: $color_orange;
  font-size: 32px;
  font-family: $font_2;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 9px;
  margin-top: 0px;

  span {
    font-family: $font_1;
    font-size: 26px;
  }
}

.productInfoContainer {
  background: $white_01;
  background-color: $white_01;

  // height: 45%;

  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  margin: 0px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0.5;
}

.productDescription {
  font-size: 16px;
  font-family: $font_1;
  letter-spacing: 0px;
  line-height: 19px;
  color: $gray;
  max-width: 35%;
  text-align: center;
  word-break: break-word;
}

.orderButton {
  // cursor: pointer;
  // font-size: 16px;
  // text-align: center;
  // font-family: $font_2;
  // background: $color_orange;
  // border: none;
  // padding: 22px 40px 22px 80px;
  // border-radius: 35px;
  // color: #ffffff !important;

  img {
    // margin-right: 20px;
    // margin-left: -20px;
  }
}

.orderButton:hover {
  // background: $color_orange;
  // border: none;
  // color: #ffffff !important;
}

.closeButton {
  display: none;
}

.errorMessageText {
  font-family: $font_3;
  font-size: 18px;
  color: $white;
  text-transform: capitalize;
  justify-content: center;
  margin-top: 25px;
  text-align: center;
}

.socialButtonImages {
  width: 80px;
}

.socialButtonImagesNew {
  width: 40px;
  height: 40px;
}

.socialButtonLogo {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 45px;
  padding: 12px;
}

.socialButtonLogoNew {
  // background: rgba(0, 0, 0, 0.2);
  // border-radius: 45px;
  padding: 6px;
}

.brandNameSubText {
  font-family: Araboto-regular;
  font-size: 24px;
  color: $white;
  text-transform: uppercase;
  justify-content: center;
  margin-bottom: 25px;
}

.show-loading-animation.rect-shape,
.show-loading-animation.round-shape,
.show-loading-animation.text-row,
.show-loading-animation .rect-shape,
.show-loading-animation .round-shape,
.show-loading-animation .text-row {
  animation: react-placeholder-pulse 1.5s infinite;
}

@keyframes react-placeholder-pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.columnDiv {
  flex-direction: column;
}

.socialMediaName {
  font-size: 12px;
  font-family: $font_1;
  color: #101225;
  margin-bottom: 4px;
  text-align: center;
  letter-spacing: 0px;
  width: max-content;
}

.arabic {
  font-family: Araboto-regular !important;
}

.arabic-bold {
  font-family: Araboto-bold !important;
}

.bonabeelRegText {
  width: 100px;
}

.websiteLogo {
  padding: 20px;
}

.bottomButtons {
  max-width: 100%;
  padding: 1px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: $white_01;
  margin: -1px 0px;
}

.notSelected {
  width: 50px;
  margin-bottom: -20px;
  z-index: 4;
}

.selectedImage {
  border: 5px solid #ff8d04 !important;
}

.logout {
  flex: 1;
  font-size: 16px;
  text-align: right;
  color: $white;
  font-family: $font_0;
  text-decoration: underline;
  padding: 20px 40px;
  align-self: flex-end;
  cursor: pointer;
}

.bottomMessage {
  background-color: transparent;
  color: $black;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  background: $white_01;
  font-family: $font_1;
}

.logo {
  // width: 150px;
  margin-bottom: 20px;
}

.logoImage {
  align-self: center;
  width: 120px;
}

.sectionStyle {
  padding: 0px;
  justify-content: center;
  flex-direction: column;
}

.selectDropDownDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.maxProductsText {
  text-align: center;
}

.likePowered {
  align-self: flex-end;
  background: white;
  display: flex;
  flex-direction: row;
  border-radius: 30px 0px 0px 30px;
  align-items: center;
  padding: 8px 13px;
  margin-top: 15px;
  margin-right: -15px;
}

.likePoweredAr {
  align-self: flex-start;
  background: white;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 0px 30px 30px 0px;
  align-items: center;
  padding: 8px 13px;
  margin-top: 15px;
  margin-left: -15px;
}

.poweredTextDiv {
  display: flex;
  flex-direction: row;
}

.poweredSlideText {
  font-size: 14px;
  font-family: $font_1;
  // color: #9204FE;
  color: #425466;
  margin-bottom: 0px;
  letter-spacing: 0px;
  line-height: 19px;
  // margin-left: 5px;
  // margin-right: 5px;
}

.getYoursText {
  // color: #EF7325 !important;
  color: $primary_color !important;

  font-family: $font_1;
  font-size: 16px;
  text-decoration: underline !important;
  letter-spacing: 0px;
  line-height: 19px;
  margin-left: 2px;
  text-align: left;
}

.bonabeelText {
  font-size: 15px;
}

.comptitionDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.shaheenmusicHeadText {
  font-family: Araboto-bold;
  font-size: 18px;
  color: #dea853;
  text-align: center;
  letter-spacing: 0px;
  margin-top: 0px;
  line-height: 20px;
}

.shaheenmusicPriceText {
  font-family: $font_2;
  font-size: 32px;
  color: #dea853;
  text-align: center;
  letter-spacing: 0px;
  margin-top: 9px;
  line-height: 36px;
  display: flex;
}

.shaheenmusicPriceSpanText {
  font-family: $font_2;
  font-size: 18px;
  color: #dea853;
  text-align: center;
  letter-spacing: 0px;
  margin-top: 0px;
}

.shaheenmusicRulesText {
  font-family: Araboto-bold;
  font-size: 18px;
  color: #dea853;
  text-align: center;
  margin-top: 0px;
  letter-spacing: 0px;
  line-height: 20px;
}

.rulesText {
  font-size: 13px;
  font-family: Araboto-bold;
  color: #575757;
  letter-spacing: 0px;
  line-height: 15px;
  text-align: right;
  align-self: center;
  margin-bottom: 4px;
  margin-top: 13px;
}

.musicInstaButton {
  margin-right: 0px !important;
  margin-left: 10px !important;
}

.musicInstaButtonorder {}

.ruleDiv {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
  margin-bottom: 4px;
}

.ruleNumber {
  display: block;
  font-size: 13px;
  line-height: 15px;
  font-family: Araboto-bold;
  color: #ffffff;
  background-color: #ff9d00;

  align-items: center;
  text-align: center;
  border-radius: 25px;
  margin: 0px;
  padding: 6px 10px;
}

.ruleListText {
  margin: 0px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0px;
  font-family: Araboto-regular;
  padding-right: 5px;
}

.divRulesOuter {
  align-self: center;
  margin-bottom: 20px;
}

.calendarOuterView {
  background-color: rgba(0, 0, 0, 0.16);
  padding: 10px;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productMain {
  width: "100%";
  margin: 0px 50px;
}

.footerAttachProductList {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 100;
  padding: 10px 0px;
}

.poweredBy {

  display: flex;
  flex-direction: row;
  align-items: center;

}

.vectorTop {
  position: fixed;
  height: 400px;
  width: 400px;
  top: -100px;
  z-index: -1;
  left: -150px;
}

.vectorBottom {
  position: fixed;
  height: 300px;
  width: 300px;
  bottom: -95px;
  z-index: -1;
  right: -120px;
}

.loaderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

//Link

.innerBlockLink {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.brandImageRowLink {
  width: 115px;
  // height: 108px;
  color: rgba(0, 0, 0, 0.2);
  display: flex;
}

.connectUsMain {
  display: flex;
  width: 50%;
  padding: 24px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: #F8F8FC;
  // border-radius: 30px;
}

.linkCardsMain {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 12px 20px;
  border-radius: 5px;
  border: 1px solid #EBECF4;
  margin: 8px 0px;
  box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.08),
    0px 0px 1px 0px rgba(12, 26, 75, 0.10);
}

.linkDetails {
  display: flex;
  justify-content: center;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}

.headerDetailsLink {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
}

.linkFooter {
  position: fixed;
  // bottom: 10px;
  bottom: 0px;
  z-index: 2;
  width: 50%;
  align-self: center;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  background-color: #FFF;

}

.mobileFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headPosition {
  position: fixed;
  top: 0;
}

/*========================
PrivacyPolicy page css starts
==========================*/

.privacy {
  //color: $white;
  padding: 60px 20px 0px;

  p {
    // color: $white;
    padding-left: 20%;
    padding-right: 20%;
    text-align: left;
  }

  a {
    color: $color_orange;
  }
}




.contactus-text {
  font-size: 12px;
  color: #27272E;
  font-family: $font_2;
}


.connectUsText {
  color: #27272E;
  font-family: $font_2;
  padding-bottom: 10px;
  letter-spacing: 0px;
}