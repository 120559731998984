//colors shade
$white: #ffffff;
$white_01: #f4f4f4;
$color_bon_jour_approx: #e0e0e0;
$color_gray_nurse_approx: #e8e8e8;
$color_alto_approx: #dddddd;
$color_gallery_approx: #eee;
$color_cararra_approx: rgba(236, 236, 236, 1);
$color_zircon_approx: #f5fcff;
$light-gray: #f9f9f9;
$color_wild_sand_approx: #f5f4f4;
$color_bon_jour_60_approx: rgba(224, 224, 224, 0.6);
$color_cararra_60_approx: rgba(236, 236, 236, 0.6);
$color_mountain_mist_approx: #999999;
$color_silver_chalice_approx: #aaaaaa;
$color_cararra_11_approx: rgba(236, 236, 236, 0.11);
$color_mine_shaft_approx: #333;
$color_mine_shaft_40_approx: rgba(51, 51, 51, 0.4);
$black_4: rgba(0, 0, 0, 0.04);
$black_11: rgba(0, 0, 0, 0.11);
$black_10: rgba(0, 0, 0, 0.1);
$black_17: rgba(0, 0, 0, 0.17);
$black: #000;
$theme-gray: #586082;
$color_saffron_approx: #eeb829;
// $color_orange: #ff9d00;
$color_orange: #ff005c; // replaced with rose

$gray: #909090;

//fonts
// $font_0: Agrandir-Grand;
// $font_1: Agrandir-Regular;
// $font_2: Poppins-bold;
// $font_3: Agrandir-NarrowHeavy;
$font_0: Poppins;
$font_1: Poppins-Regular;
$font_2: Poppins-bold;
$font_3: Poppins-semibiold;
//urls
// $url_0: url(../public/assets/images/screen1.png);
// $url_1: url(../public/assets/images/testimonial.jpg);
// $url_2: url(../public/assets/images/bg-pattern.png);

//Color settings
// $primary_color: #9300FF;
// $secondary_color:#5600CB ;
$primary_color: #5410bf;
$secondary_color:#5410bf;
$bg_gradiant: linear-gradient(to bottom right, $primary_color 10%, $secondary_color 90%);
$color_bay_of_many_45_approx: rgba($primary_color, 0.45);