// @use '~owl.carousel/dist/assets/owl.carousel.css';
// @use '~owl.carousel/dist/assets/owl.theme.default.css';


@font-face {
	font-family: Montserrat;
	src: url('../public/assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
	font-family: Montserrat-bold;
	src: url('../public/assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
	font-family: Montserrat-semibold;
	src: url('../public/assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
	font-family: Montserrat-regular;
	src: url('../public/assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
	font-family: Changa;
	src: url('../public/assets/fonts/Changa-Regular.ttf');
}

@font-face {
	font-family: Changa-bold;
	src: url('../public/assets/fonts/Changa-Bold.ttf');
}

@font-face {
	font-family: Changa-semibold;
	src: url('../public/assets/fonts/Changa-SemiBold.ttf');
}

@font-face {
	font-family: Changa-regular;
	src: url('../public/assets/fonts/Changa-Regular.ttf');
}

@font-face {
	font-family: Araboto;
	src: url('../public/assets/fonts/Araboto-Normal.ttf');
}

@font-face {
	font-family: Araboto-bold;
	src: url('../public/assets/fonts/Araboto-Bold.ttf');
}

@font-face {
	font-family: Araboto-semibold;
	src: url('../public/assets/fonts/Araboto-Medium.ttf');
}

@font-face {
	font-family: Araboto-regular;
	src: url('../public/assets/fonts/Araboto-Normal.ttf');
}


@font-face {
	font-family: Agrandir-Grand;
	src: url('../public/assets/fonts/Agrandir-Grand.ttf');
}

@font-face {
	font-family: Agrandir-GrandBold;
	src: url('../public/assets/fonts/Agrandir-GrandBold.ttf');
}

@font-face {
	font-family: Agrandir-Regular;
	src: url('../public/assets/fonts/Agrandir-Regular.ttf');
}

@font-face {
	font-family: Agrandir-Light;
	src: url('../public/assets/fonts/Agrandir-Light.ttf');
}

@font-face {
	font-family: Agrandir-NarrowHeavy;
	src: url('../public/assets/fonts/Agrandir-NarrowHeavy.ttf');
}

@font-face {
	font-family: Poppins;
	src: url('../public/assets/fonts/Poppins-Regular.ttf');
}

@font-face {
	font-family: Poppins-bold;
	src: url('../public/assets/fonts/Poppins-Bold.ttf');
}

@font-face {
	font-family: Poppins-semibold;
	src: url('../public/assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
	font-family: Poppins-regular;
	src: url('../public/assets/fonts/Poppins-Regular.ttf');
}

// @import './assets/css/font-awesome.min.css';
@import './assets/css/bootstrap.min.css';
@import './assets/css/animate.min.css';
@import './assets/scss/style.scss';